import { useCallback, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import {
  useAddConditionValues,
  useGetConditionValues,
  useGetFields,
  useGetInitValues,
} from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import {
  PaperlessBillingQuestion,
  RecurringPaymentQuestion,
} from '@ecp/features/sales/shared/questions';
import { submitProofDraft, useField, useForm, usePniRef } from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { AffiliationForm, MultiProductDiscountQuestion } from '../../../../common';
import { ProtectiveDeviceForm } from '../../forms';
import { useStyles } from './PropertyDiscountsPageForm.styles';

interface SubmitParams {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
  policyType: string;
}

interface Props {
  onNext: () => Promise<void>;
  policyType: string;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
    policyType,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();
      await dispatch(
        submitProofDraft({
          policyTypes: [policyType],
        }),
      );
      await onNext();
    }

    setIsSubmitting(false);
  };

export const PropertyDiscountsPageForm: React.FC<Props> = (props) => {
  const { onNext, policyType } = props;
  const { classes } = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getFields = useGetFields();
  const dispatch = useDispatch();
  const getConditions = useGetConditionValues();
  const getInitValues = useGetInitValues();
  const pniRef = usePniRef();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  const parentPolicyNumber = useField(`${pniRef}.parentsPolicyNumber`);
  const commercialPolicyNumber = useField(`${pniRef}.commercialPolicyNumber`);
  const otherInsuredObjectsAndPolicies = useField(`${pniRef}.otherInsuredObjectsAndPolicies`);

  const multiProductDiscountValues = otherInsuredObjectsAndPolicies.value as string[];
  const isParentPolicySelected = multiProductDiscountValues?.includes('PARENT_POLICY');
  const isCommercialPolicySelected = multiProductDiscountValues?.includes('COMMERCIAL_AUTO');
  const isRentersDiscounts = policyType === 'renters';

  const handleSubmit = useCallback(async () => {
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
        policyType,
      }),
    );
  }, [dispatch, onNext, patchFormValues, validateForm, policyType]);

  useAddConditionValues({
    conditionalFields: [parentPolicyNumber, commercialPolicyNumber],
    isExcluded: () => !isParentPolicySelected || !isCommercialPolicySelected,
    isRequiredOverride: () => isParentPolicySelected || isCommercialPolicySelected,
  });

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container rowSpacing='20px'>
          <GridItem topSpacing='lg' bottomSpacing='xs' xs={12}>
            <h3>Policy-Level Discounts</h3>
          </GridItem>
          <GridItem xs={12}>
            <PaperlessBillingQuestion
              defaultValue={isRentersDiscounts ? true : false}
              lob={policyType}
            />
          </GridItem>
          <GridItem xs={12}>
            <RecurringPaymentQuestion
              defaultValue={isRentersDiscounts ? true : false}
              lob={policyType}
            />
          </GridItem>
          <AffiliationForm />
          <Divider orientation='horizontal' className={classes.horizontalDivider} />
          <MultiProductDiscountQuestion lob={policyType} />
          <Divider orientation='horizontal' className={classes.horizontalDivider} />
          <ProtectiveDeviceForm />
          <GridItem topSpacing='sm' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='continue'
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='discounts'
              analyticsElement='choice.discounts.continueButton'
              type='submit'
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
