import {
  EquipmentBreakdownCoverage,
  IdentityFraudExpenseCoverage,
  JewelryCoverage,
  SewerSepticSumpCoverage,
} from '@ecp/sales/lob/property';
import type { OptionsMetadata } from '@ecp/types';

import { PropertyPolicyCoverageGroup } from '../../../utils';

export const PropertyPolicyCoveragesMeta: { [productKey: string]: OptionsMetadata } = {
  'amfam-adv.home': {
    'dwelling.limit': {
      title: 'Coverage A - Dwelling',
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'dwelling.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
        'dwelling.replacementCost': {
          title: 'Percentage (% ) of Replacement Cost',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsPercentage: true,
          },
        },
        'dwelling.valuation': {
          title: 'Valuation Method',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
        'dwelling.perils': {
          title: 'Covered Perils',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'otherStructuresBlanket.limit': {
      title: 'Coverage B - Other Structures Blanket',
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'otherStructuresBlanket.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
        'otherStructuresBlanket.valuation': {
          title: 'Valuation Method',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
  },
  'amfam-adv.renters': {
    'personalLiability.limit': {
      title: 'Coverage E - Personal Liability',
      parentGroup: PropertyPolicyCoverageGroup.EXTENDEDPROTECTION,
      subCoverages: {
        'personalLiability.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'medical.limit': {
      title: 'Coverage F - Medical Expense',
      parentGroup: PropertyPolicyCoverageGroup.EXTENDEDPROTECTION,
      subCoverages: {
        'medical.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'personalProperty.limit': {
      title: 'Coverage C - Personal Property',
      // primaryText: `Personal Property or Possessions coverage covers your belongings if they're stolen or damaged in the event of a covered loss.`,
      // secondaryText: `This includes clothing, TVs, appliances, furniture, etc.`,
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'personalProperty.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
        'personalProperty.valuation': {
          title: 'Valuation Method',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'personalProperty.increaseLimit': {
          title: 'Renewal increase',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'personalProperty.perils': {
          title: 'Covered Perils',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'lossOfUse.limit': {
      title: 'Coverage D - Loss Of Use',
      parentGroup: PropertyPolicyCoverageGroup.COVERAGESFORDWELLING,
      subCoverages: {
        'lossOfUse.propertyLimit': {
          title: 'Percentage (%) of Coverage C - Personal Property',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
        'lossOfUse.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'sectionOneDeductibles.otherPerils': {
      title: 'Property Deductible',
      primaryText: 'Property Deductible',
      secondaryText: '',
      parentGroup: PropertyPolicyCoverageGroup.DEDUCTIBLES,
      displayInfo: {
        displayElement: 'DropDown',
      },
    },
    'buildingAdditions.limit': {
      title: 'Building Additions And Alterations',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'buildingAdditions.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'TextField',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'animalLiability.limit': {
      title: 'Dangerous Dog and Exotic Animal Liability',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'animalLiability.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'fireService.limit': {
      title: 'Fire Department Service Charge',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'fireService.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'lossAssessment.limit': {
      title: 'Loss Assessments',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'lossAssessment.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'DropDown',
          },
        },
      },
    },
    'moldCoverage.sectionILimit': {
      title: 'Fungi Or Bacteria',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'moldCoverage.sectionILimit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'equipmentBreakdown.limit': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.equipmentBreakdown.premiumAmount',
      displayInfo: {
        displayElement: <EquipmentBreakdownCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'identityFraudExpense.limit': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      premiumAmountNode: 'amfam-adv.renters.coverages.identityFraudExpense.premiumAmount',
      displayInfo: {
        displayElement: <IdentityFraudExpenseCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'jewelry.limit': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.COMMONOPTIONAL,
      displayInfo: {
        displayElement: <JewelryCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
    'outsideWaterSource.limit': {
      title: 'Water Coverage From an Outside Water Source',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      subCoverages: {
        'outsideWaterSource.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
            displayAsDollarAmount: true,
          },
        },
      },
    },
    'pet.selected': {
      title: 'Pet',
      primaryText: 'Pet',
      secondaryText: '',
      parentGroup: PropertyPolicyCoverageGroup.LESSCOMMONOPTIONAL,
    },
    'replacementCost.selected': {
      title: 'Personal Property Replacement Cost',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'replacementCost.selected': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'pollutionCleanup.limit': {
      title: 'Pollutant Cleanup And Removal',
      parentGroup: PropertyPolicyCoverageGroup.INCLUDED,
      subCoverages: {
        'pollutionCleanup.limit': {
          title: 'Limit',
          displayInfo: {
            displayElement: 'ReadOnly',
          },
        },
      },
    },
    'waterBackup.sewer': {
      title: '',
      parentGroup: PropertyPolicyCoverageGroup.WATERPROTECTION,
      premiumAmountNode: 'amfam-adv.renters.coverages.waterBackup.premiumAmount',
      displayInfo: {
        displayElement: <SewerSepticSumpCoverage carrierName='amfam-adv' product='renters' />,
      },
    },
  },
};
