import { useCallback, useState } from 'react';

import { FormControlLabel, Grid } from '@mui/material';

import { useAddFields } from '@ecp/features/sales/form';
import { Checkbox } from '@ecp/features/sales/shared/components';
import type { CoverageQuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import { PolicyCenterDialog } from '../../components';
import { useStyles } from './SewerSepticSumpCoverage.styles';

export const SewerSepticSumpCoverage: React.FC<CoverageQuestionProps> = (props) => {
  const { classes } = useStyles();
  const { carrierName, product } = props;
  const coveragePrefix = `${carrierName}.${product}.coverages`;
  const sewerField = useFieldWithPrefix(coveragePrefix)('waterBackup.sewer');
  const septicField = useFieldWithPrefix(coveragePrefix)('waterBackup.septic');
  const sumpField = useFieldWithPrefix(coveragePrefix)('waterBackup.sump');
  const { title: sewerTitle = '' } = sewerField.question;
  const { title: septicTitle = '' } = septicField.question;
  const { title: sumpTitle = '' } = sumpField.question;

  useAddFields({ [sewerField.key]: sewerField });
  useAddFields({ [septicField.key]: septicField });
  useAddFields({ [sumpField.key]: sumpField });

  const sewerFieldSelectedOption = sewerField?.question?.options?.find(
    (sewerOption) => sewerOption.value !== 'false',
  );

  const septicFieldSelectedOption = septicField?.question?.options?.find(
    (septicOption) => septicOption.value !== 'false',
  );

  const sumpFieldSelectedOption = sumpField?.question?.options?.find(
    (sumpOption) => sumpOption.value !== 'false',
  );

  const [dialogStat, setDialogStat] = useState(false);

  const handleChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      setDialogStat(newChecked);
      if (newChecked) {
        sewerField?.update(sewerFieldSelectedOption?.value);
        septicField?.update(septicFieldSelectedOption?.value);
        sumpField?.update(sumpFieldSelectedOption?.value);
      } else {
        sewerField?.update('false');
        septicField?.update('false');
        sumpField?.update('false');
      }
    },
    [
      sewerField,
      sewerFieldSelectedOption,
      septicField,
      septicFieldSelectedOption,
      sumpField,
      sumpFieldSelectedOption,
    ],
  );

  const toggleDialog = useCallback(() => {
    setDialogStat((prev: boolean) => {
      if (prev) {
        sewerField?.validateAndUpdate('false');
        septicField?.validateAndUpdate('false');
        sumpField?.validateAndUpdate('false');
      }

      return !prev;
    });
  }, [sewerField, septicField, sumpField]);

  const handleDialogSubmit = useCallback(() => {
    setDialogStat(!dialogStat);
    // TODO: handle redirection to Policy Center Page
  }, [dialogStat]);

  if (!sewerField.exists || !septicField.exists || !sumpField.exists) return null;

  const isChecked =
    sewerField.value === sewerFieldSelectedOption?.value &&
    septicField.value === septicFieldSelectedOption?.value &&
    sumpField.value === sumpFieldSelectedOption?.value;

  return (
    <>
      <div className={classes.root}>
        <Grid container gap={1} justifyItems='space-between'>
          <Grid item xs={12} className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.formControlCheckBox}
                  id='sewerSepticSumpCoverage'
                  checked={isChecked}
                  onChange={handleChange}
                  trackingName='sewerSepticSumpCoverage'
                  trackingLabel={isChecked.toString()}
                />
              }
              label={
                <div
                  className={classes.label}
                >{`${sewerTitle}, ${septicTitle}, and ${sumpTitle}`}</div>
              }
              className={classes.formControlLabel}
            />
          </Grid>
        </Grid>
      </div>
      {dialogStat && (
        <PolicyCenterDialog
          dialogStat={dialogStat}
          toggleDialog={toggleDialog}
          dialogSubmit={handleDialogSubmit}
        />
      )}
    </>
  );
};
