import { Fragment, useCallback, useEffect, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { getAge } from '@ecp/utils/date';

import { GridItem, SnackbarAlert } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getDrivers,
  getPrimaryInsuredStateCode,
  getVehicles,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  AwayAtSchoolQuestion,
  AwayAtSchoolSubQuestions,
  getCanRecieveDefensiveDriverDiscount,
  getNumberOfDiscounts,
  useShowDiscountsSnackbar,
} from '@ecp/sales/lob/auto';
import {
  DefensiveDriverQuestion,
  fetchDriverDiscounts,
  GoodStudentQuestion,
  YoungVolunteerQuestion,
} from '@ecp/sales/lob/auto';

import { useStyles } from './DriverDiscountsForm.styles';

export const DriverDiscountsForm: React.FC<QuestionProps> = (props) => {
  const drivers = useSelector(getDrivers);
  const { classes } = useStyles();
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const vehicles = useSelector(getVehicles);
  // Get all drivers that are eligible for away at school and/or good student discounts
  const applicableDrivers = drivers.filter((driver) => {
    const age = getAge(driver.dateOfBirth);
    if (!age || age > 24) return null;
    else return driver.ref;
  });

  const [showConflictDialog, setShowConflictDialog] = useState(
    useShowDiscountsSnackbar(applicableDrivers, false),
  );
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(
    useShowDiscountsSnackbar(applicableDrivers, true),
  );

  // Take those drivers and use their refs to check if they have the aforementioned discounts selected
  const driverDiscounts = fetchDriverDiscounts(applicableDrivers);

  const numberOfGoodStudentDrivers = getNumberOfDiscounts(driverDiscounts, 'goodStudent');
  const numberOfAwayAtSchoolDrivers = getNumberOfDiscounts(driverDiscounts, 'awayAtSchool');
  const hasAnyAwayAtSchool = numberOfAwayAtSchoolDrivers > 0;
  const hasAnyGoodStudent = numberOfGoodStudentDrivers > 0;
  const hasMultipleAwayAtSchool = numberOfAwayAtSchoolDrivers > 1;
  const hasMultipleGoodStudent = numberOfGoodStudentDrivers > 1;

  const snackbarWording = (): string => {
    if (showConflictDialog) {
      return 'Away at School or Good Student discounts';
    } else if (showDuplicateDialog) {
      if (hasMultipleAwayAtSchool) {
        return 'Away at School discount';
      }

      return 'Good Student discount';
    } else return '';
  };

  // Update conflict dialog state whenever AAS, GSD, or # of Vehicles changes
  useEffect(() => {
    setShowConflictDialog(hasAnyAwayAtSchool && hasAnyGoodStudent && vehicles.length === 1);
    setShowDuplicateDialog(
      (hasMultipleAwayAtSchool || hasMultipleGoodStudent) && vehicles.length === 1,
    );
  }, [
    hasAnyAwayAtSchool,
    hasAnyGoodStudent,
    vehicles.length,
    setShowConflictDialog,
    hasMultipleAwayAtSchool,
    hasMultipleGoodStudent,
  ]);

  const onDismissSnackbar = useCallback(() => {
    setShowConflictDialog(false);
    setShowDuplicateDialog(false);
  }, [setShowConflictDialog, setShowDuplicateDialog]);

  const snackbarMessage = (
    <p className={classes.snackBarContent}>
      When two drivers drive the same vehicle, both are not eligible for the {snackbarWording()}.
      Remove the conflicting discount to continue.
    </p>
  );

  const showSnackbar = showConflictDialog || showDuplicateDialog;
  const allDiscounts = drivers.map((driver, index) => {
    const hasSameFirstName =
      drivers.filter((driverElt) => {
        return driverElt.firstName === driver.firstName;
      }).length > 1;
    let displayname = driver.firstName;
    if (hasSameFirstName) {
      if (driver.middleName) {
        displayname = `${driver.firstName} ${driver.middleName}`;
      } else if (driver.suffix) {
        displayname = `${driver.firstName} ${driver.suffix}`;
      }
    }
    const age = getAge(driver.dateOfBirth);
    if (!age || (age > 24 && !getCanRecieveDefensiveDriverDiscount(age, stateCode))) return null;

    return (
      <Fragment key={index}>
        {showSnackbar && (
          <SnackbarAlert
            message={snackbarMessage}
            vertical='bottom'
            horizontal='center'
            open={showSnackbar}
            onClose={onDismissSnackbar}
            autoHideDuration={5000}
            severity='error'
            hideActionButton
          />
        )}
        <GridItem key={`${driver.ref}-discounts`} xs={12}>
          <h3
            className={classes.driverText}
            key={`${driver.ref}-header`}
          >{`${displayname}'s Discounts`}</h3>
          <span className={classes.textTertiary} key={`${driver.ref}-optional`}>
            {' '}
            (optional)
          </span>
          {/* Away at School Discount is not available when only 1 driver on a submission  */}
          {drivers.length > 1 && (
            <GridItem topSpacing='lg' xs={12} key={`${driver.ref}-awayAtSchool`}>
              <>
                <AwayAtSchoolQuestion
                  driverRef={driver.ref}
                  driverAge={age}
                  driverName={displayname}
                  key={`${driver.ref}-awayAtSchoolComp`}
                />
                <AwayAtSchoolSubQuestions driverRef={driver.ref} />
              </>
            </GridItem>
          )}
          <GridItem topSpacing='lg' key={`${driver.ref}-goodStudent`} xs={12}>
            <GoodStudentQuestion
              driverRef={driver.ref}
              driverAge={age}
              driverName={displayname}
              key={`${driver.ref}-goodStudentComp`}
            />
          </GridItem>
          <GridItem topSpacing='lg' xs={12} key={`${driver.ref}-youngVolunteer`}>
            <YoungVolunteerQuestion
              driverRef={driver.ref}
              driverAge={age}
              driverName={displayname}
              key={`${driver.ref}-youngVolunteerComp`}
            />
          </GridItem>
          <GridItem topSpacing='lg' xs={12} key={`${driver.ref}-defensiveDriverQuestion`}>
            <DefensiveDriverQuestion
              driverRef={driver.ref}
              driverAge={age}
              driverName={displayname}
              key={`${driver.ref}-defensiveDriverQuestion`}
            />
          </GridItem>
        </GridItem>
        <Divider className={classes.divider} key={`${driver.ref}-divider`} />
      </Fragment>
    );
  });

  return (
    <Grid container>
      <GridItem xs={12} />
      {allDiscounts}
    </Grid>
  );
};
