import { useCallback } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { updatePageStatus } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { ThirdPartyInterestPageForm } from '../../forms';

export const ThirdPartyInterestPage: React.FC = () => {
  const dispatch = useDispatch();

  const navigateToNextPage = useNavigateToNextPage();

  const handleNext = useCallback(async () => {
    await navigateToNextPage();
    // EDSP-11442 Quote page is skipped while offer state is bindable.
    // Adding page status INVALID and inProgress false will make the inactive
    dispatch(updatePageStatus(NavStatus.INVALID, PagePath.QUOTES, false));

    return;
  }, [dispatch, navigateToNextPage]);

  return (
    <div>
      <Page
        title='Third-Party Interest (TPI)'
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.THIRD_PARTY_INTEREST} />,
        }}
      >
        <ThirdPartyInterestPageForm onNext={handleNext} />
      </Page>
    </div>
  );
};
