import { FormLabel } from '@mui/material';

import { TooltipWithIcon } from '@ecp/components';
import type { AnswerValue } from '@ecp/types';

import { useStyles } from './ReadOnlyField.styles';

interface ReadOnlyFieldProps {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  label: string;
  value: AnswerValue;
  helperText?: string | React.ReactElement; // To render helper text in ToolTip.
  assistiveText?: string | React.ReactElement; // To render helper text in page (below the label).
  id?: string;
  dataTestId?: string;
}

export const ReadOnlyField: React.FC<ReadOnlyFieldProps> = (props) => {
  const { classes, cx } = useStyles(undefined, { props });

  const {
    label,
    value,
    helperText,
    dataTestId = label,
    id,
    assistiveText,
    classes: propsClasses,
  } = props;

  return (
    <div>
      <FormLabel
        component='legend'
        error={false}
        focused={false}
        classes={{
          root: propsClasses ? cx(classes.label, propsClasses.label) : classes.label,
        }}
        id={id}
        data-testid={dataTestId}
      >
        {label}
        {helperText && <TooltipWithIcon className={classes.toolTip} title={helperText} />}
        {assistiveText && <p className={classes.secondaryText}>{assistiveText}</p>}
      </FormLabel>
      <FormLabel
        component='legend'
        error={false}
        focused={false}
        classes={{
          root: classes.value,
        }}
        id={`${id}-value`}
        data-testid={`${dataTestId}-value`}
      >
        {value}
      </FormLabel>
    </div>
  );
};
