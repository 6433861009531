import type { PropertyProductName } from '@ecp/features/shared/product';

import type { PropertyPolicyCoverageGroupKey } from './types';

export const getPropertyPolicyCoverageGroupNames = (
  product: PropertyProductName,
): Record<PropertyPolicyCoverageGroupKey, string> | null => {
  switch (product) {
    case 'renters': {
      return {
        COVERAGESFORDWELLING: 'Coverages for the Rental Unit',
        DEDUCTIBLES: 'Deductibles',
        EXTENDEDPROTECTION: 'Extended Protection',
        INCLUDED: 'Included Coverages',
        COMMONOPTIONAL: 'Common Optional Coverages',
        LESSCOMMONOPTIONAL: 'Less Common Optional Coverages',
        WATERPROTECTION: 'Water Protection',
        OTHER: 'Other Coverages',
      };
    }
    case 'home':
      return {
        COVERAGESFORDWELLING: 'Coverages for the Home',
        DEDUCTIBLES: 'Deductibles',
        EXTENDEDPROTECTION: 'Extended Protection',
        INCLUDED: 'Included Coverages',
        COMMONOPTIONAL: 'Common Optional Coverages',
        LESSCOMMONOPTIONAL: 'Less Common Optional Coverages',
        WATERPROTECTION: 'Water Protection',
        OTHER: 'Other Coverages',
      };
    default:
      return null;
  }
};
