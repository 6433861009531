import { castAnswerType } from '@ecp/utils/common';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  getShownFields,
  useDeltaField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Field, Fields } from '@ecp/types';

export const usePropertyField = (
  answerKeyWithoutPrefix: string,
  hideFieldBasedOnAnswers = false,
): Field<AnswerValue> | null => {
  const field = useFieldWithPrefix('property', 'property')(answerKeyWithoutPrefix);

  return useInitAndAddField(field, hideFieldBasedOnAnswers);
};

export const usePropertyDeltaField = (
  ref: string,
  answerKeyWithoutPrefix: string,
  hideFieldBasedOnAnswers = false,
): Field<AnswerValue> | null => {
  const field = useDeltaField(ref, answerKeyWithoutPrefix);

  return useInitAndAddField(field, hideFieldBasedOnAnswers);
};

export const useInitAndAddField = (
  field: Field<AnswerValue>,
  hideFieldBasedOnAnswers: boolean,
): Field<AnswerValue> | null => {
  const initValue = castAnswerType(field.question?.defaultValue, field.question.answerType);
  useInitValues({ [field.key]: initValue });
  useAddFields({ [field.key]: field });

  // This is needed for fields with hide attribute depending on some other node
  const dispatch = useDispatch();
  const filteredFields = hideFieldBasedOnAnswers
    ? (dispatch(getShownFields({ fields: { [field.key]: field } })) as Fields)
    : null;

  if (!field.exists || (filteredFields && !filteredFields[field.key])) return null;

  return field;
};
