interface MetadataItem {
  displayText: string;
  documentName: AdditionalDocumentName;
}

export type AdditionalDocuments = Record<AdditionalDocumentName, MetadataItem>;

export type AdditionalDocumentName = 'PremiumStatementFullPay' | 'PremiumStatementMonthly';

const metadata: AdditionalDocuments = {
  PremiumStatementFullPay: {
    displayText: 'Statement of Premium with Full Pay Discount',
    documentName: 'PremiumStatementFullPay',
  },
  PremiumStatementMonthly: {
    displayText: 'Statement of Premium without Full Pay Discount',
    documentName: 'PremiumStatementMonthly',
  },
};

export default metadata;
