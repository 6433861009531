import { Divider, Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAffiliations, usePniRef, useSniRef } from '@ecp/features/sales/shared/store';

import { DeltaCosctoMembershipNumberQuestion } from '../DeltaCosctoMembershipNumberQuestion';
import { useStyles } from './DeltaAffinityCard.styles';

interface DeltaAffinityCardProps {
  isReadOnly?: boolean;
}

export const DeltaAffinityCard: React.FC<DeltaAffinityCardProps> = (props) => {
  const { isReadOnly } = props;
  const { classes } = useStyles();

  const pniRef = usePniRef();
  const sniRef = useSniRef();

  const pniAffiliationList = useAffiliations(pniRef);
  const sniAffiliationList = useAffiliations(sniRef);

  const isPniCostoMembershipExists = pniAffiliationList.some((affiliation) => {
    return affiliation.group === 'Costco';
  });

  const isSniCostoMembershipExists = sniAffiliationList.some((affiliation) => {
    return affiliation.group === 'Costco';
  });

  if (!isPniCostoMembershipExists && !isSniCostoMembershipExists) return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <h2>Affinity Programs</h2>
        <Divider className={classes.divider} />
      </GridItem>
      <Grid container className={classes.body} columnSpacing={1.5}>
        {isPniCostoMembershipExists && (
          <DeltaCosctoMembershipNumberQuestion
            isReadOnly={isReadOnly}
            key={pniRef}
            niRef={pniRef}
          />
        )}
      </Grid>
      <Grid container className={classes.body} columnSpacing={1.5}>
        {isSniCostoMembershipExists && (
          <DeltaCosctoMembershipNumberQuestion
            isReadOnly={isReadOnly}
            key={sniRef}
            niRef={sniRef}
          />
        )}
      </Grid>
    </>
  );
};
