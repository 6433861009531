import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { useSingleFoundation } from '@ecp/features/sales/quotes/property/home';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import { useInitAndAddField } from '../../utils';

export const FinishedBasementLivingAreaQuestion: React.FC<QuestionProps> = (props) => {
  const { singleFoundationRef: foundationRef } = useSingleFoundation();
  const finishedBasementLivingAreaQuestionField = useFieldWithPrefix(
    foundationRef,
    'foundation.<id>',
  )('finishedBasementLivingAreaSQF');

  const field = useInitAndAddField(finishedBasementLivingAreaQuestionField, true);

  const {
    trackingName = 'square_footage',
    label = finishedBasementLivingAreaQuestionField?.question?.title,
  } = props;

  if (!field) return null;

  return (
    <NumberFormat
      {...finishedBasementLivingAreaQuestionField.props}
      fullWidth={false}
      format='####'
      id='finishedBasementLivingAreaQuestionField' // This is to fix accessibility test
      groupLabel={<Typography variant='body4'>{label}</Typography>}
      trackingName={trackingName}
    />
  );
};
