import { Grid } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import { downloadAndSaveDocuments } from '@ecp/features/sales/checkout';
import { Button } from '@ecp/features/sales/shared/components';
import { getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './DownloadAdditionalDocuments.styles';
import metadata from './metadata';
import type { AdditionalDocuments } from './metadata/metadata';

export const DownloadAdditionalDocuments: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const selectedProducts = useSelector(getOfferProductsSelected);
  const handleDownloadAdditionalDocument = useEvent((documentName: string) => async () => {
    for (const product of selectedProducts) {
      await dispatch(
        downloadAndSaveDocuments({
          product: product,
          documentTypeName: documentName,
          documentDisplayName: documentName,
        }),
      );
    }
  });

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <h3>Additional Documents</h3>
      </Grid>
      <ul className={classes.listItemsContent}>
        {Object.entries(metadata as AdditionalDocuments).map(([key, value]) => {
          return (
            <li key={key} className={classes.paddingLeft}>
              <Grid item xs={12}>
                <Button
                  classes={{
                    root: classes.documentButton,
                  }}
                  onClick={handleDownloadAdditionalDocument(value.documentName)}
                  trackingName={`download_${value.documentName}`}
                  trackingLabel={value.documentName}
                  analyticsElement={`choice.policySummaryPage.${value.documentName}`}
                  data-testid='downloadAdditionalDocument'
                  variant='iconTextMedium'
                >
                  {value.displayText}
                </Button>
              </Grid>
            </li>
          );
        })}
      </ul>
    </Grid>
  );
};
