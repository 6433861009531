import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'DownloadAdditionalDocuments',
})((theme) => ({
  paddingLeft: {
    paddingLeft: 0,
  },
  listItemsContent: {
    paddingLeft: '30px',
    margin: 0,
    color: theme.palette.text.link,
  },
  documentButton: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  root: {
    justifyContent: 'start',
    padding: 24,
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: '4px',
    marginTop: 25,
    gap: 10,
  },
}));
