import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyDeltaField } from '../../utils/usePropertyField';

export const DeltaWiringTypeQuestion: React.FC<QuestionProps> = (props) => {
  const wiringTypeField = usePropertyDeltaField('root', 'property.wiringType');

  if (!wiringTypeField) return null;

  const { label = wiringTypeField.question?.title, trackingName = 'wiring_type_drop_down' } = props;

  return (
    <Select
      id='wiringType'
      {...wiringTypeField.props}
      fullWidth={false}
      groupLabel={label}
      trackingName={trackingName}
      inputButtonAriaLabel='wiringType'
    />
  );
};
