import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { GarageSingleQuestion } from './GarageSingleQuestion';
import { GaragesMultipleQuestion } from './GaragesMultipleQuestion';

export type GarageQuestionProps = QuestionProps & {
  allowMultipleGarages?: boolean;
};

export const GarageQuestion: React.FC<GarageQuestionProps> = (props) => {
  const { allowMultipleGarages = false, ...rest } = props;

  if (allowMultipleGarages) {
    return <GaragesMultipleQuestion {...rest} />;
  }

  return <GarageSingleQuestion {...rest} />;
};
