import { TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyDeltaField } from '../../utils/usePropertyField';

export const DeltaWiringTypeOtherDescriptionQuestion: React.FC<QuestionProps> = (props) => {
  const wiringTypeDescriptionField = usePropertyDeltaField(
    'root',
    'property.otherWiringTypeDescription',
    true,
  );
  if (!wiringTypeDescriptionField) return null;

  const {
    label = wiringTypeDescriptionField.question?.title,
    trackingName = 'wiring_type_other_description',
  } = props;

  return (
    <TextField
      {...wiringTypeDescriptionField?.props}
      ariaLabel='Wiring Type Description'
      groupLabel={label}
      trackingLabel={trackingName}
      trackingName={trackingName}
      required
    />
  );
};
