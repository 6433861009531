import { Divider, Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useField } from '@ecp/features/sales/shared/store';
import type { ProductName } from '@ecp/features/shared/product';
import { type Product } from '@ecp/features/shared/product';
import { DeltaDiscountsEmailQuestion } from '@ecp/sales/lob/auto';

import { useStyles } from './DeltaPaperlessCard.styles';

export interface DeltaPaperlessSectionProps {
  product: Product;
  policyType: ProductName;
  isReadOnly?: boolean;
}

export const DeltaPaperlessCard: React.FC<DeltaPaperlessSectionProps> = (props) => {
  const { product, policyType, isReadOnly } = props;
  const { classes } = useStyles();

  const paperlessStatus = useField(`discount.${policyType}.paperless`);
  const autoPay = useField(`discount.${policyType}.autoPay`);
  const autoPayType = useField(`delta.coverageOption.${product}.autoPayType`);

  if (!paperlessStatus.value) return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <h2>Paperless</h2>
        <Divider className={classes.divider} />
      </GridItem>
      <Grid container className={classes.body} columnSpacing={1.5}>
        <Grid item xs={12}>
          <Grid container className={classes.dataGrids}>
            <GridItem topSpacing='xs' xs={12} md={12}>
              {autoPay.value && autoPayType.value === 'MY_ACCOUNT_BILLING' && (
                <span className={classes.subTextWrapper}>
                  Email address for paperless will be used for Autopay
                </span>
              )}
              <DeltaDiscountsEmailQuestion
                product={product}
                discountType='paperlessEmail'
                isPaperlessSelected={!!paperlessStatus.value}
                isReadOnly={isReadOnly}
              />
            </GridItem>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
