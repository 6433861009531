import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { castAnswerType } from '@ecp/utils/common';
import { getYearsOptions } from '@ecp/utils/date';

import { NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

const fromYear = 1920;
const endYear = dayjs().year();
const roofYearsOptions = getYearsOptions(endYear, fromYear);

export const RoofInstallationDateQuestion: React.FC<QuestionProps> = (props) => {
  const roofInstallOrReplace = useFieldWithPrefix('property', 'property')('roof.installOrReplace');
  const yearBuiltField = useFieldWithPrefix('property', 'property')('yearBuilt');

  useInitValues({
    [roofInstallOrReplace.key]: castAnswerType(
      yearBuiltField.value,
      roofInstallOrReplace.question.answerType,
    ),
  });
  useAddFields({ [roofInstallOrReplace.key]: roofInstallOrReplace });

  const {
    label = roofInstallOrReplace?.question?.title,
    trackingName = 'roof_age_dropdown',
    dataTestId = 'roofInstallOrReplace',
    displayType,
  } = props;

  if (!roofInstallOrReplace) return null;

  if (displayType === 'NumberFormat')
    return (
      <NumberFormat
        {...roofInstallOrReplace.props}
        groupLabel={<Typography variant='body4'>{label}</Typography>}
        format='####'
        id='roofInstallOrReplace'
        name='roofInstallOrReplace'
        placeholder='YYYY'
        data-testid={dataTestId}
        trackingName={trackingName}
        fullWidth={false}
      />
    );

  return (
    <Select
      {...roofInstallOrReplace.props}
      fullWidth={false}
      options={roofYearsOptions}
      groupLabel={label}
      id='roofInstallOrReplace'
      data-testid={dataTestId}
      trackingName={trackingName}
      inputButtonAriaLabel='roofInstallOrReplace'
    />
  );
};
