import { env } from '@ecp/env';
import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoDeltaPageForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    margin: '0 auto',
    [theme.breakpoints.only('xl')]: {
      maxWidth: 1084,
      padding: env.static.isAgent ? '0 30px' : 0,
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: theme.breakpoints.values.md,
      padding: '0 15px',
    },
  },
  carrierButtons: {
    display: 'flex',
    gap: 20,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));
