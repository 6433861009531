import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

interface Props extends QuestionProps {
  personRef: string;
  disabled?: boolean;
}

export const GenderQuestion: React.FC<Props> = (props) => {
  const { personRef, trackingName = 'gender', trackingLabel, disabled } = props;
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const gender = usePersonField('gender');
  useAddFields({ gender });

  if (!gender.exists) return null;

  return (
    <RadioGroupWithOptions
      {...(gender.props as OptionProps)}
      label='Gender'
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      disabled={disabled}
    />
  );
};
