import { ReadOnlyField } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

export type HomeReplacementCostProps = QuestionProps & {
  isReadOnly?: boolean;
};

export const HomeReplacementCostQuestion: React.FC<HomeReplacementCostProps> = ({
  isReadOnly = true,
  ...props
}) => {
  const {
    groupLabel = 'Calculated Home Replacement Cost',
    dataTestId = 'HomeReplacementCost - label',
  } = props;

  const homeReplacementCostValue = useSelector((state: RootStore) => {
    return state.inquiry.answers['property.replacementCostValuationId'];
  });

  if (!homeReplacementCostValue) return null;

  // Creating only a read only version of the component by default.
  // If needed, this can be expanded for other partners.
  if (isReadOnly) {
    return (
      <ReadOnlyField
        label={groupLabel}
        value={homeReplacementCostValue}
        id={dataTestId}
        dataTestId={dataTestId}
      />
    );
  }

  return null;
};
