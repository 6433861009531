import { useCallback, useMemo } from 'react';

import { ensureStringArray, isEmpty } from '@ecp/utils/common';
import { getAge } from '@ecp/utils/date';

import { GridItem } from '@ecp/components';
import { CheckboxGroup } from '@ecp/features/sales/shared/components';
import {
  getFieldsByKeys,
  getFieldValue,
  getPersonInfo,
  getPniPersonInfo,
  getPrimaryInsuredStateCode,
  updateAnswers,
  useField,
  usePniRef,
  useSniRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { CommercialPolicyNumberQuestion } from '../CommercialPolicyNumberQuestion';
import { ParentsPolicyNumberQuestion } from '../ParentsPolicyNumberQuestion';
import metadata from './metadata';
import type { Metadata } from './metadata/metadata';
import { useStyles } from './MultiProductDiscountQuestion.styles';

interface MultiProductDiscountQuestionProps {
  lob: string;
}
export const MultiProductDiscountQuestion: React.FC<MultiProductDiscountQuestionProps> = (
  props,
) => {
  const { lob } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const pniRef = usePniRef();
  const sniRef = useSniRef();
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const { dateOfBirth } = useSelector(getPniPersonInfo);

  const sniInfo = useSelector((state: RootStore) =>
    sniRef ? getPersonInfo(state, sniRef) : undefined,
  );
  const otherInsuredObjectsAndPolicies = useField(`${pniRef}.otherInsuredObjectsAndPolicies`);
  const isParentPolicyApplicable = useMemo(() => {
    const ageLimit = 25;
    const pniAge = getAge(dateOfBirth);
    const sniAge = getAge(sniInfo?.dateOfBirth as string);

    if ((pniAge && pniAge < ageLimit) || (sniAge && sniAge < ageLimit)) return true;
    else return false;
  }, [dateOfBirth, sniInfo?.dateOfBirth]);
  const filterMetadataByProduct = (metadata: Metadata): Metadata => {
    const filteredMetadata: Metadata = {};

    Object.entries(metadata).forEach(([categoryCode, { label, cardOptions }]) => {
      const filteredCardOptions = cardOptions.filter((option) => {
        if (option.value === 'PARENT_POLICY') {
          return isParentPolicyApplicable && !option.state?.includes(stateCode);
        } else {
          return !option.exclude || (option.exclude && !option.exclude?.includes(lob));
        }
      });

      if (filteredCardOptions.length > 0) {
        filteredMetadata[categoryCode] = {
          label,
          cardOptions: filteredCardOptions,
        };
      }
    });

    return filteredMetadata;
  };

  const filteredMeta = filterMetadataByProduct(metadata);

  const multiProductDiscountsValues = useSelector((state: RootStore) => {
    return Object.entries(metadata).reduce((acc, [categoryCode, metadataItem]) => {
      const keys = metadataItem.cardOptions.map((discountOption) => discountOption.value);
      const fields = getFieldsByKeys(state, `person.otherInsureObjectsAndPolicies`, keys, dispatch);
      const personMultiProductDiscountsField = ensureStringArray(
        getFieldValue(state, `${pniRef}.otherInsuredObjectsAndPolicies`),
      );

      acc[categoryCode] = Object.entries(fields).reduce((acc, [discountKey]) => {
        if (
          !isEmpty(personMultiProductDiscountsField) &&
          personMultiProductDiscountsField?.includes(discountKey)
        )
          acc.push(discountKey);

        return acc;
      }, [] as string[]);

      return acc;
    }, {} as Record<string, string[]>);
  });

  const handleMultiProductDiscountsChange = useCallback(
    async (values: string[], updatedCategoryCode: string) => {
      const updatedCategoryCodeDiscountKeys = Object.entries(metadata).reduce(
        (acc, [categoryCode, metadataItem]) => {
          const keys = metadataItem.cardOptions.map((discountOption) => discountOption.value);
          if (updatedCategoryCode === categoryCode) return keys;

          return acc;
        },
        [] as string[],
      );

      const currentMultiProductDiscountKeys = ensureStringArray(
        otherInsuredObjectsAndPolicies.value,
      );
      const otherCategoryCodeDiscountKeys = currentMultiProductDiscountKeys.filter(
        (discountKey) => !updatedCategoryCodeDiscountKeys.includes(discountKey),
      );
      const updatedDiscountKeys = [...otherCategoryCodeDiscountKeys, ...values];

      await dispatch(
        updateAnswers({
          answers: { [`${pniRef}.otherInsuredObjectsAndPolicies`]: updatedDiscountKeys },
        }),
      );
    },
    [dispatch, otherInsuredObjectsAndPolicies.value, pniRef],
  );

  if (!Object.keys(filteredMeta).length) return null;

  return (
    <>
      <GridItem topSpacing='sm' xs={12}>
        <h3>Multi-Product Discounts</h3>
      </GridItem>
      {Object.keys(filteredMeta).map((category) => (
        <GridItem key={filteredMeta[category].label} xs={12}>
          <CheckboxGroup
            name={filteredMeta[category].label || 'MultiProduct Discounts'}
            options={filteredMeta[category].cardOptions}
            label={filteredMeta[category].label}
            // eslint-disable-next-line react/jsx-no-bind
            actionOnComplete={(value) => handleMultiProductDiscountsChange(value, category)}
            values={multiProductDiscountsValues[category]}
            trackingName='driver_discounts'
            variant='horizontalCardCuztomizedLabel'
            classes={classes}
          />
          {category === 'commercialAuto' && <CommercialPolicyNumberQuestion pniRef={pniRef} />}
        </GridItem>
      ))}
      {isParentPolicyApplicable && <ParentsPolicyNumberQuestion pniRef={pniRef} />}
    </>
  );
};
