import { useCallback, useMemo } from 'react';

import { ensureStringArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { AFFILIATION_REF_SUFFIX } from '@ecp/features/sales/shared/constants';
import {
  createRef,
  deleteInquiryRef,
  getAllValues,
  getAnswer,
  getDeltaField,
  getField,
  getInquiryLoaded,
  getQuestion,
  getValueForPrefix,
  setFormErrorsChangedByField,
  updateAddedRef,
  updateAnswers,
  useField,
  useFieldWithPrefix,
  usePniRef,
  useSniRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, FieldsDef } from '@ecp/features/sales/shared/types';
import type { Fields, Option } from '@ecp/types';

export interface Affiliation {
  affiliationType: string;
  group: string;
  ref: string;
}

interface AffiliationOptions {
  typeMap: Record<string, string>;
  groupMap: Record<string, string>;
}

export interface AffiliationFields extends Fields {
  affiliation: FieldsDef<Affiliation>;
}

const getAffiliation = (
  ref: string,
  allValues: Answers,
  typeMap: Record<string, string>,
  groupMap: Record<string, string>,
): Affiliation => {
  const getString = getValueForPrefix<string>(ref, allValues);

  return {
    ref,
    affiliationType: typeMap[getString('affiliationType')],
    group: groupMap[getString('group')],
  };
};

export const useGetAffiliationFields = (affiliationRef: string): AffiliationFields => {
  const userAffiliationField = useFieldWithPrefix(affiliationRef, 'affiliation.<id>');

  return {
    affiliation: {
      ref: useField(affiliationRef),
      affiliationType: userAffiliationField('affiliationType'),
      group: userAffiliationField('group'),
    },
  };
};

const arrayToObject = (array: Option[]): Record<string, string> =>
  array.reduce((obj, item) => {
    // TODO StringMap must really be { [key: string]: React.ReactElement | string }
    // Change type and make sure all usage doesn't have unexpected behavior
    // @ts-ignore FIXME ASAP
    obj[item.value] = item.label;

    return obj;
  }, {} as Record<string, string>);

const useAffiliationOptions = (): AffiliationOptions => {
  const groupQuestion = useSelector(getQuestion('affiliation.<id>.group'));
  const groupMap = useMemo(() => {
    return arrayToObject(groupQuestion.options || []);
  }, [groupQuestion?.options]);

  const typeQuestion = useSelector(getQuestion('affiliation.<id>.affiliationType'));
  const typeMap = useMemo(() => {
    return arrayToObject(typeQuestion.options || []);
  }, [typeQuestion?.options]);

  return { typeMap, groupMap };
};

export const useCreateAffiliation = (): (() => string) => {
  const dispatch = useDispatch();

  return useCallback(() => dispatch(createRef('affiliation')), [dispatch]);
};

export const useCurrentAffiliationGroups = (affiliationList: Affiliation[]): (() => string[]) => {
  return useCallback(() => {
    const affiliationGroups: string[] = [];
    affiliationList.forEach((aff) => {
      if (aff.group === 'American Family') affiliationGroups.push('AMFAM');
      else if (aff.group === 'University of Wisconsin - Madison')
        affiliationGroups.push('UW_MADISON');
      else if (aff.group === 'Sanford Health') affiliationGroups.push('SANFORD_MED_GRP');
      else if (aff.group === 'Costco') affiliationGroups.push('COSTCO');
    });

    return affiliationGroups;
  }, [affiliationList]);
};

export const useAffiliation = (affiliationRef: string): Affiliation => {
  const allValues = useSelector(getAllValues);
  const { typeMap, groupMap } = useAffiliationOptions();

  return getAffiliation(affiliationRef, allValues, typeMap, groupMap);
};

export const useAffiliations = (niRef: string): Affiliation[] => {
  const allValues = useSelector(getAllValues);

  const { typeMap, groupMap } = useAffiliationOptions();

  const refs: string[] = ensureStringArray(allValues[`${niRef}.${AFFILIATION_REF_SUFFIX}`]);

  return refs.map((ref) => getAffiliation(ref, allValues, typeMap, groupMap));
};

export interface AddAffiliationResult {
  // the api has received the update
  done: Promise<string>;
  affiliationRef: string;
}

export const useAddAffiliation = (niRef?: string): (() => string) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(() => {
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'libs/features/sales/shared/store/lib/src/modelUtil/affiliationModelUtil.ts',
          functionOrigin: 'useAddAffiliation/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }

    const affiliationRef = niRef ? dispatch(createRef('affiliation')) : '';

    dispatch(
      updateAddedRef({
        type: `${niRef}.${AFFILIATION_REF_SUFFIX}`,
        newRef: affiliationRef,
      }),
    );

    return affiliationRef;
  }, [dispatch, inquiryLoaded, niRef]);
};
export const useRemoveAffiliation = (
  niRef: string,
): ((affiliation: Affiliation) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (affiliation: Affiliation) => {
      if (affiliation) {
        // This block executes when an affiliation attached to a named insured is removed
        if (niRef) {
          if (affiliation.group === 'Costco') {
            await dispatch(
              updateAnswers({
                answers: {
                  [`${niRef}.additionalInformation.costcoMembershipNumber`]: null,
                },
              }),
            );
          }
          await dispatch(
            deleteInquiryRef({ refType: 'affiliation', refId: affiliation.ref.split('.')[1] }),
          );
        }
      }
    },
    [dispatch, niRef],
  );
};

export const useRemoveAllAffiliation = (niRef: string): (() => void) => {
  const affiliationRefsKey = `${niRef}.affiliation.ref`;
  const dispatch = useDispatch();
  const affiliationRefsValue = useSelector((state: RootStore) =>
    getAnswer(state, affiliationRefsKey),
  ) as Array<string>;

  return useCallback(() => {
    if (affiliationRefsValue && affiliationRefsValue.length > 0) {
      affiliationRefsValue.forEach(async (affiliationRef) => {
        await dispatch(
          updateAnswers({
            answers: {
              [`${niRef}.additionalInformation.costcoMembershipNumber`]: null,
            },
          }),
        );
        await dispatch(
          deleteInquiryRef({ refType: 'affiliation', refId: affiliationRef.split('.')[1] }),
        );
      });
    }
  }, [affiliationRefsValue, dispatch, niRef]);
};

const useGetAffiliations = (): ((niRef: string) => Affiliation[]) => {
  const allValues = useSelector(getAllValues);

  const { typeMap, groupMap } = useAffiliationOptions();

  return useCallback(
    (niRef: string) => {
      const refs: string[] = ensureStringArray(allValues[`${niRef}.${AFFILIATION_REF_SUFFIX}`]);

      return refs.map((ref) => getAffiliation(ref, allValues, typeMap, groupMap));
    },
    [allValues, groupMap, typeMap],
  );
};

const useValidateCostcoSelected = (): ((niRef: string) => boolean) => {
  const affiliationList = useGetAffiliations();

  return useCallback(
    (niRef: string) => {
      return affiliationList(niRef).some((affiliation) => {
        return affiliation.group === 'Costco';
      });
    },
    [affiliationList],
  );
};

export const useValidateCostcoMembershipField = (): (() => boolean) => {
  const pniRef = usePniRef();
  const sniRef = useSniRef();
  const dispatch = useDispatch();
  const isCostcoSelected = useValidateCostcoSelected();
  const getDeltaCostoMembership = useSelector((state: RootStore) => (niRef: string) => {
    const key = getDeltaField(state, niRef, 'costcoMembershipNumber');

    return getField(state, { key, dispatch });
  });

  return useCallback(() => {
    const result = [pniRef, sniRef].some((e) => {
      let hasError;
      if (e) {
        if (isCostcoSelected(e)) {
          const field = getDeltaCostoMembership(e);
          hasError = !field.props.value;
          if (hasError) {
            // EDSP-11036 Since SAPI is not sending the requiredness, making these changes
            dispatch(
              setFormErrorsChangedByField({
                key: field.key,
                errors: ['Costco Membership Number is required.'],
              }),
            );
          }
        }
      }

      return !!hasError;
    });

    return result;
  }, [dispatch, getDeltaCostoMembership, isCostcoSelected, pniRef, sniRef]);
};
