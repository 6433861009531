import { env } from '@ecp/env';
import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PropertyDeltaForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    margin: '0 auto',
    [theme.breakpoints.only('xl')]: {
      maxWidth: 1084,
      padding: env.static.isAgent ? '0 30px' : 0,
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: theme.breakpoints.values.md,
      padding: '0 15px',
    },
  },
  content: {
    margin: 0,
    width: '100%',
  },
  body: {
    padding: '0 0 20px 8px',
    width: '100%',
    marginLeft: 0,
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      padding: '0 20px 20px 8px',
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
    height: 4,
    borderBottomWidth: 0,
  },
}));
