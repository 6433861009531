import { useCallback } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { updatePageStatus } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { PropertyLossesPageForm } from '../../forms';

export const RentersLossesPage: React.FC = () => {
  const dispatch = useDispatch();

  const navigateToNextPage = useNavigateToPage(PagePath.RENTERS_DISCOUNTS);

  const navigateToNext = useCallback(async () => {
    dispatch(updatePageStatus(NavStatus.VALID));
    await navigateToNextPage();
  }, [dispatch, navigateToNextPage]);

  return (
    <Page
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.RENTERS_LOSSES} />,
      }}
      title='Review Property Losses'
      analyticsElement='choice.rentersLossess.page'
    >
      <PropertyLossesPageForm onNext={navigateToNext} />
    </Page>
  );
};
