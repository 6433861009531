import { useCallback, useState } from 'react';

import { useField } from '@ecp/features/sales/shared/store';
import type { Driver } from '@ecp/features/sales/shared/types';
import type { AnswerValue, Field } from '@ecp/types';

import metadata from '../components/DefensiveDriver/metadata';
import type { ConflictingField } from '../types';

const awayAtSchoolSuffix = '.discount.awayAtSchool';
const goodStudentSuffix = '.discount.goodStudent';
interface DriverDiscountsObject {
  awayAtSchool: boolean;
  goodStudent: boolean;
}
export interface MappedDriverDiscounts {
  driverRef: string;
  discounts: DriverDiscountsObject;
}
export const getNumberOfDiscounts = (
  driverDiscounts: MappedDriverDiscounts[],
  key: 'awayAtSchool' | 'goodStudent',
): number => {
  const filteredDiscounts = driverDiscounts.filter((driver) => {
    return driver.discounts[key] === true;
  })?.length;

  return filteredDiscounts;
};
export const fetchDriverDiscounts = (applicableDrivers: Driver[]): MappedDriverDiscounts[] => {
  const mappedDiscounts = applicableDrivers.map((driver) => {
    const awayAtSchool = useField(`${driver.ref}${awayAtSchoolSuffix}`).value || (false as boolean);
    const goodStudent = useField(`${driver.ref}${goodStudentSuffix}`).value || (false as boolean);
    const mappedDiscounts = {
      awayAtSchool: awayAtSchool,
      goodStudent: goodStudent,
    };
    const formattedDriverDiscount = {
      driverRef: driver.ref,
      discounts: mappedDiscounts,
    } as MappedDriverDiscounts;

    return formattedDriverDiscount;
  });

  return mappedDiscounts;
};
export const useShowDiscountsSnackbar = (
  applicableDrivers: Driver[],
  checkForDuplicates: boolean,
): boolean => {
  // Get all drivers that could have away at school or good student discounts
  // Take those drivers and use their refs to check if they have the aforementioned discounts selected
  const driverDiscounts = fetchDriverDiscounts(applicableDrivers);
  const hasAnyAwayAtSchool = getNumberOfDiscounts(driverDiscounts, 'goodStudent') > 0;
  const hasAnyGoodStudent = getNumberOfDiscounts(driverDiscounts, 'awayAtSchool') > 0;

  // if any AAS and GS discounts are true, show the Snackbar

  return checkForDuplicates
    ? hasAnyAwayAtSchool || hasAnyGoodStudent // if checking for duplicates, return whether either if true
    : hasAnyAwayAtSchool && hasAnyGoodStudent; // if checking for any AAS or GS, return whether both are true;
};

export const useDiscountsUtil = (
  changedField: Field<AnswerValue>,
  conflictingFields: ConflictingField[],
  specificValue?: string, // needs to be set when field is not a boolean
  supressAutoUpdate?: boolean,
): {
  showRemoveDialog: boolean;
  dismissRemove: () => void;
  handleDriverDiscountChange: (
    newChecked: AnswerValue,
    event?: React.ChangeEvent<unknown>,
  ) => boolean;
  handleConflicts: () => void;
} => {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const dismissRemove = useCallback((): void => {
    setShowRemoveDialog(false);
  }, []);

  const handleDriverDiscountChange = useCallback(
    (newChecked: AnswerValue, event?: React.ChangeEvent<unknown>): boolean => {
      if (event) {
        event.preventDefault();
      }
      const showDialog = conflictingFields.some((field) => {
        // if the changed field is a string value
        if (specificValue) {
          const isChangedValueAConflict = newChecked === specificValue;
          // If a conflicting field is not a boolean, and we need to check for a string value(s)
          if (field.conflictingValues && !isChangedValueAConflict) {
            return field.conflictingValues.includes(field.field.value);
          }

          // conflicting field is a boolean
          return !!field.field.value && isChangedValueAConflict;
        }

        // If changed field is a boolean
        if (field.conflictingValues) {
          // and field has a conflicting field that has a string value
          return field.conflictingValues.includes(field.field.value);
        }

        // conflicting field has a boolean value of true
        return !!field.field.value;
      });
      if (showDialog) {
        setShowRemoveDialog(true);
      } else if (!supressAutoUpdate) {
        changedField.props.actionOnComplete(newChecked);
      }

      return showDialog;
    },
    [changedField.props, conflictingFields, specificValue, supressAutoUpdate],
  );

  const handleConflicts = useCallback((): void => {
    conflictingFields.forEach((removedField) => {
      removedField.field.props.actionOnComplete(removedField.newValue);
    });
    if (!supressAutoUpdate) {
      changedField.props.actionOnComplete(specificValue || true);
    }
    setShowRemoveDialog(false);
  }, [changedField.props, conflictingFields, specificValue, supressAutoUpdate]);

  return {
    showRemoveDialog,
    dismissRemove,
    handleDriverDiscountChange,
    handleConflicts,
  };
};

export const getCanRecieveDefensiveDriverDiscount = (
  driverAge: number,
  stateCode: string,
): boolean => {
  if (!metadata.stateOptions?.[stateCode]) return false;
  const minimumAge = metadata.stateOptions[stateCode].ageMinimum;

  return minimumAge ? driverAge >= minimumAge : true;
};

export const getRequiresDefensiveDriverCourse = (stateCode: string): boolean => {
  return metadata.stateOptions?.[stateCode]?.requiresCourse || false;
};

export const getFieldHelpTextByState = (stateCode: string): string => {
  return metadata.stateOptions?.[stateCode]?.helpText || '';
};
