import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ReadOnlyField' })((theme) => ({
  label: {
    ...theme.typography.body4,
    paddingBottom: 15,
  },
  value: {
    ...theme.typography.body4,
  },
  toolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
  },
  secondaryText: {
    ...theme.typography.body1,
    fontSize: '14px',
    fontWeight: 400,
    color: '#4C4C4C',
  },
}));
