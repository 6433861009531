import { useCallback, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { OfferType } from '@ecp/features/sales/shared/constants';
import {
  applyDelta,
  getIsBundleForOfferProductsSelected,
  getLineOfBusiness,
  updateOffers,
  updateOfferType,
  useField,
  useForm,
  useValidateCostcoMembershipField,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName, PropertyProduct } from '@ecp/features/shared/product';
import { LineOfBusiness } from '@ecp/features/shared/product';

import {
  DeltaAffinityCard,
  DeltaAutopayCard,
  DeltaPaperlessCard,
  MailingAddressQuestion,
} from '../../../../common';
import { HomeDeltaQuestionsCard } from '../../components';
import { useStyles } from './PropertyDeltaPageForm.styles';

interface PropertyDeltaPageFormProps {
  onNext: () => Promise<void>;
  product: PropertyProduct;
  policyType: ProductName;
}
interface SubmitParams {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
  isBundle: boolean;
  lob: LineOfBusiness;
  policyType: ProductName;
  valdiateCostcoMembershipField: () => boolean;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
    isBundle,
    lob,
    policyType,
    valdiateCostcoMembershipField,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();

      if (isBundle) {
        // Apply Delta when Bundle
        // For monoline flow this will be handled on TPI page
        await dispatch(applyDelta({ policyTypes: [policyType] }));
      } else {
        if (lob === LineOfBusiness.RENTERS) {
          // Apply Delta when for Renters monoline
          // For other monoline flow this will be handled on TPI page
          await dispatch(applyDelta({ policyTypes: [policyType] }));
          // Put offers if Renters monoline
          // For other monoline flows and bundle flows this will be handled on TPI page
          await dispatch(updateOfferType({ offerType: OfferType.OFFER_WITH_PREMIUM }));
          await dispatch(updateOffers({ force: true }));
        }
      }

      if (valdiateCostcoMembershipField()) {
        setIsSubmitting(false);

        return;
      }

      await onNext();
      setIsSubmitting(false);
    }

    setIsSubmitting(false);
  };

export const PropertyDeltaPageForm: React.FC<PropertyDeltaPageFormProps> = (props) => {
  const { onNext, product, policyType } = props;
  const { classes } = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const lob: LineOfBusiness = useSelector(getLineOfBusiness);
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const getFields = useGetFields();
  const dispatch = useDispatch();
  const getConditions = useGetConditionValues();
  const getInitValues = useGetInitValues();
  const valdiateCostcoMembershipField = useValidateCostcoMembershipField();
  const paperless = useField('discount.auto.paperless');
  const autoPay = useField('discount.auto.autoPay');
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  const handleSubmit = useCallback(async () => {
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
        isBundle,
        lob,
        policyType,
        valdiateCostcoMembershipField,
      }),
    );
  }, [
    dispatch,
    onNext,
    patchFormValues,
    validateForm,
    isBundle,
    lob,
    policyType,
    valdiateCostcoMembershipField,
  ]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container spacing={1} className={classes.content}>
          <HomeDeltaQuestionsCard policyType={policyType} />
          <GridItem topSpacing='lg' xs={12}>
            <h2>Contact Information</h2>
            <Divider className={classes.divider} />
          </GridItem>
          <Grid container className={classes.body} columnSpacing={1.5}>
            <GridItem topSpacing='lg' xs={10}>
              <MailingAddressQuestion isReadOnly={isBundle} />
            </GridItem>
          </Grid>
          <DeltaAffinityCard isReadOnly={isBundle} />
          <DeltaPaperlessCard
            product={product}
            policyType={policyType}
            isReadOnly={isBundle && !!paperless.value}
          />
          <DeltaAutopayCard
            product={product}
            policyType={policyType}
            isReadOnly={isBundle && !!autoPay.value}
          />

          {/* ADD Home questions form here */}
          <GridItem topSpacing='sm' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='continue'
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='property_delta_continue'
              analyticsElement='choice.propertyDeltaForm.saveAndContinueButton'
              type='submit'
            >
              'Save & Continue'
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
