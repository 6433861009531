import { useEffect } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { getOfferProductsSelectedByType } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { PropertyDeltaPageForm } from '../../forms';

export const HomeDeltaPage: React.FC = () => {
  const { property: propertyProduct } = useSelector(getOfferProductsSelectedByType);
  const productName = propertyProduct && getProductNameFromProduct(propertyProduct);

  const handleNext = useNavigateToNextPage();

  useEffect(() => {
    // TODO: Revisit the analytics as a whole for ASP
    trackSapiAnalyticsEvent({
      element: 'choice.HomeDeltaPage.page',
      event: 'render',
      eventDetail: 'true',
    });
  }, []);

  if (!propertyProduct || !productName) return null;

  return (
    <Page
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.HOME_DELTA} />,
      }}
      title='Home Additional Information'
      mainWithoutSidebar
    >
      <PropertyDeltaPageForm
        onNext={handleNext}
        product={propertyProduct}
        policyType={productName}
      />
    </Page>
  );
};
