import { useCallback, useRef } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import {
  ConstructionTypeDescriptionQuestion,
  ConstructionTypeQuestion,
  ExteriorWallConstructionDescriptionQuestion,
  ExteriorWallConstructionQuestion,
  FoundationTypeQuestion,
  GarageQuestion,
  HomeBuiltOnSlopeQuestion,
  OtherFoundationTypeDescriptionQuestion,
  RoofClassDocumentQuestion,
  RoofClassQuestion,
  RoofInstallationDateQuestion,
  RoofMaterialDescriptionQuestion,
  RoofMaterialQuestion,
} from '@ecp/sales/lob/property';

import { RoofShapeQuestion } from '../../components';
import { optionsFiltering } from '../../utils';
import { useStyles } from './HomeExteriorPageForm.styles';

export interface Props {
  onNext: () => Promise<void>;
}

const optionsDependencyRules = {
  contingentAnswerPrefix: 'property',
  contingentQuestionPrefix: 'property',
  contingentNameWithoutPrefix: 'constructionType',
  dependencyRules: optionsFiltering,
};

export const HomeExteriorPageForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });

  const handleSubmit = useCallback(async () => {
    if (validateForm().isValid) {
      await patchFormValues();
      trackSapiAnalyticsEvent({
        element: 'choice.homeExteriorPage.saveAndContinueButton',
        event: 'click',
        eventDetail: 'true',
      });
      await onNext();
    }
  }, [validateForm, patchFormValues, onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <GridItem xs={12}>
            <FoundationTypeQuestion
              displayType='RadioGroupWithOptions'
              displayTypeForHomeBasementPercentageFinished='Select'
            />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <OtherFoundationTypeDescriptionQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <ConstructionTypeQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <ConstructionTypeDescriptionQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <ExteriorWallConstructionQuestion
              displayType='RadioGroupWithOptions'
              optionsDependencyRules={optionsDependencyRules}
            />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <ExteriorWallConstructionDescriptionQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RoofShapeQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RoofMaterialQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RoofMaterialDescriptionQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <GarageQuestion allowMultipleGarages />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RoofClassQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RoofClassDocumentQuestion />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <RoofInstallationDateQuestion displayType='NumberFormat' />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <HomeBuiltOnSlopeQuestion />
          </GridItem>

          <Grid item xs={12}>
            <NextPageInstructions divider>
              Thanks! Next, we&apos;ll get details on the inside of the home.
            </NextPageInstructions>
          </Grid>
          <Grid container item xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress}
              className={classes.next}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='home_exterior_continue'
              data-testid='homeExteriorSaveAndContinue'
            >
              Save & continue
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
