import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoSelectDiscountsForm' })((theme) => ({
  driverText: {
    ...theme.typography.h3,
    display: 'inline',
  },
  textTertiary: {
    color: theme.palette.text.tertiary,
  },
  divider: {
    margin: '40px 0px 40px 0px',
    width: '100%',
  },
  snackBarContent: {
    ...theme.typography.body1,
    color: theme.palette.error.contrastText,
  },
}));
