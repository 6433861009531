import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GridItem, TextField } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useDeltaField, useField, usePniRef } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import type { AnswerValue } from '@ecp/types';

export interface Props extends QuestionProps {
  product: Product;
  discountType: string;
  isPaperlessSelected?: boolean;
  sectionType?: string;
  isReadOnly?: boolean;
}

export interface EmailOptions {
  label: string;
  value: string;
}
// TODO: Move into shared folder, once created as this is used in property and auto
export const DeltaDiscountsEmailQuestion: React.FC<Props> = (props) => {
  const { product, discountType, isPaperlessSelected, sectionType, isReadOnly } = props;
  // TODO: Static Question not why we are using these. Please look into
  const email = useField(`static.delta.coverageOption.${product}.${discountType}`);
  const newEmail = useDeltaField(`coverageOption.${product}`, discountType);
  const autoPayType = useDeltaField(`coverageOption.${product}`, 'autoPayType');
  let showAutoPayEmail = false;
  if (sectionType && sectionType === 'autoPay') {
    showAutoPayEmail = autoPayType.value === 'MY_ACCOUNT_BILLING' && !isPaperlessSelected;
  } else {
    showAutoPayEmail = true;
  }
  const pniRef = usePniRef();
  const pniEmail = useSelector((state: RootStore) => state.inquiry.answers[`${pniRef}.email`]) as
    | string
    | undefined;

  useInitValues(
    pniEmail
      ? { [email.key]: pniEmail, [newEmail.key]: pniEmail }
      : { [email.key]: 'Add New Email', [newEmail.key]: null },
  );
  useAddFields({ [email.key]: email, [newEmail.key]: newEmail });

  const getEmailOptions = (options: string[]): EmailOptions[] =>
    options.map((value) => ({
      label: value,
      value,
    }));

  const onEmailOptionChange = useCallback(
    (value: AnswerValue): void => {
      email.props.actionOnChange(value);
      if (value === pniEmail) {
        newEmail.props.actionOnComplete(pniEmail);
      } else {
        newEmail.props.actionOnChange('');
      }
    },
    [email, newEmail, pniEmail],
  );
  if (!email.exists) return null;
  if (!showAutoPayEmail) return null;

  return (
    <Grid container columnSpacing={1}>
      {pniEmail && (
        <GridItem topSpacing='xs' md={5} xs={12}>
          <Select
            {...email.props}
            label='Email'
            id='email'
            options={getEmailOptions(pniEmail ? [pniEmail, 'Add New Email'] : ['Add New Email'])}
            trackingName='program_email'
            actionOnChange={onEmailOptionChange}
            disabled={isReadOnly}
          />
        </GridItem>
      )}
      {email.value === 'Add New Email' && (
        <GridItem topSpacing='xs' md={5} xs={12}>
          <TextField
            {...newEmail.props}
            label={pniEmail ? 'New Email' : 'Email'}
            trackingName='program_new_email'
          />
        </GridItem>
      )}
    </Grid>
  );
};
