import { GridItem, useShowMoreOrLess } from '@ecp/components';
import { RadioGroupWithOptions, Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const GarageSingleQuestion: React.FC<QuestionProps> = (props) => {
  const garageType = usePropertyField('garage.type');
  const garageSize = usePropertyField('garage.size');

  const {
    showMore: showMoreGarageType,
    displayOptions: displayGarageType,
    handleClick: handleGarageTypeShowMoreOrLess,
  } = useShowMoreOrLess(
    garageType?.props.options,
    4,
    'GarageTypeMoreChoices',
    (value: string) => `GarageType${value}Choices`,
  );

  if (!garageType) return null;

  const { label = garageType.question?.title, trackingName = 'garage_type_button' } = props;

  // TODO: 'NONE_EXT' to be removed when sapi cleans up option type values to be uniform

  return (
    <>
      {garageType.exists && (
        <RadioGroupWithOptions
          {...garageType.props}
          options={displayGarageType}
          label={label}
          showMoreOrLessProps={{
            showMore: showMoreGarageType,
            onClick: handleGarageTypeShowMoreOrLess,
            groupTestId: 'GarageTypeMoreChoices',
          }}
          trackingName={trackingName}
          id='GarageType'
          cardSize='small'
        />
      )}
      {garageSize?.exists &&
        garageType.value &&
        !['GARAGE.TYPE.NONE', 'NONE_EXT'].includes(garageType.value) && (
          <GridItem topSpacing='lg' xs={12}>
            <Slider
              {...garageSize.props}
              groupLabel={garageSize.question.title}
              trackingName='garage_size_slider'
            />
          </GridItem>
        )}
    </>
  );
};
