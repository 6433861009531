import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { PRIMARY_INSURED_PERSON_LOCK } from '@ecp/features/sales/shared/constants';
import {
  DateOfBirthQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import { useField, usePniRef } from '@ecp/features/sales/shared/store';

import {
  MaritalStatusQuestion,
  PriorAddressQuestions,
  RiskAddressQuestion,
} from '../../../components';
import { useStyles } from './InsuredInformationPageFormQuestions.styles';

interface Props {
  isValidAddress: boolean;
}

export const InsuredInformationPageFormQuestions: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { isValidAddress } = props;

  const pniRef = usePniRef();
  const primaryInsuredPersonLock = useField(PRIMARY_INSURED_PERSON_LOCK);
  const isLocked = Boolean(primaryInsuredPersonLock.props.value);

  return (
    <Grid container>
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <FirstNameQuestion
          personRef={pniRef}
          disabled={isLocked}
          addHelperText={false}
          label='First Name'
        />
      </GridItem>

      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
        <MiddleNameQuestion personRef={pniRef} disabled={isLocked} label='MI (optional)' />
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <LastNameQuestion personRef={pniRef} disabled={isLocked} label='Last Name' />
      </GridItem>

      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
        <SuffixQuestion personRef={pniRef} disabled={isLocked} />
      </GridItem>

      <GridItem topSpacing='md' xs={12} md={6} className={classes.columnLeft}>
        <DateOfBirthQuestion
          id='DOB'
          personRef={pniRef}
          disabled={isLocked}
          hidePicker={false}
          label='Date of Birth'
        />
      </GridItem>
      <GridItem topSpacing='md' xs={12} md={6} className={classes.columnRight}>
        <MaritalStatusQuestion personRef={pniRef} label='Marital Status' />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <RiskAddressQuestion isValidAddress={isValidAddress} />
      </GridItem>

      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <EmailQuestion personRef={pniRef} />
      </GridItem>

      <GridItem topSpacing='sm' xs={12}>
        <PriorAddressQuestions />
      </GridItem>
    </Grid>
  );
};
