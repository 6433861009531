import { Fragment } from 'react';

import { Divider, Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useDeltaField, useField } from '@ecp/features/sales/shared/store';
import type { Driver, Vehicle } from '@ecp/features/sales/shared/types';
import type { ProductName } from '@ecp/features/shared/product';
import { type AutoProduct } from '@ecp/features/shared/product';
import {
  DeltaDriverCard,
  DeltaNonOperatorCard,
  DeltaProgramsCard,
  DeltaVehicleCard,
} from '@ecp/sales/lob/auto';

import {
  DeltaAffinityCard,
  DeltaAutopayCard,
  DeltaPaperlessCard,
  MailingAddressQuestion,
} from '../../../../../common';
import { useStyles } from './AutoDeltaPageFormQuestions.styles';

export interface AutoDeltaPageFormQuestionsProps {
  drivers: Driver[];
  vehicles: Vehicle[];
  product: AutoProduct;
  policyType: ProductName;
}

export const AutoDeltaPageFormQuestions: React.FC<AutoDeltaPageFormQuestionsProps> = (props) => {
  const { drivers, vehicles, product, policyType } = props;
  const { classes } = useStyles();
  const paperless = useField(`discount.${policyType}.paperless`);
  const autoPay = useField(`discount.${policyType}.autoPay`);
  const autoPayType = useDeltaField(`coverageOption.${product}`, 'autoPayType');
  const operators = drivers.filter((driver) => driver.driverStatus === 'OPERATOR');
  const nonOperators = drivers.filter((driver) => driver.driverStatus === 'NON_OPERATOR');
  const excludedOperators = drivers.filter((driver) => driver.driverStatus === 'EXCLUDED_OPERATOR');

  return (
    <Grid container spacing={1} className={classes.content}>
      {/* Contact Information*/}
      <GridItem topSpacing='lg' xs={12}>
        <h2>Contact Information</h2>
        <Divider className={classes.divider} />
      </GridItem>
      <Grid container className={classes.body} columnSpacing={1.5}>
        <GridItem topSpacing='lg' xs={12}>
          <MailingAddressQuestion />
        </GridItem>
      </Grid>
      {/* Vehicle Section */}
      {vehicles.length > 0 && (
        <>
          <GridItem xs={12} className={classes.header}>
            <h2>About your vehicles</h2>
            <Divider className={classes.divider} />
          </GridItem>
          <Grid container className={classes.body} columnSpacing={1.5}>
            {vehicles.map((vehicle, index) => (
              <Fragment key={vehicle.ref}>
                <Grid item xs={12}>
                  <Grid container className={classes.dataGrids}>
                    <GridItem xs={12}>
                      <DeltaVehicleCard vehicle={vehicle} index={index} />
                    </GridItem>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </>
      )}

      {/* Driver Section */}
      {operators.length > 0 && (
        <>
          <GridItem topSpacing='lg' xs={12}>
            <h2>About your drivers</h2>
            <Divider className={classes.divider} />
          </GridItem>
          <Grid container className={classes.body} columnSpacing={1.5}>
            {operators.map((driver, index) => (
              <Fragment key={driver.ref}>
                <Grid item xs={12}>
                  <Grid container className={classes.dataGrids}>
                    <Grid item xs={12}>
                      <DeltaDriverCard driver={driver} index={index} />
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </>
      )}

      {/* NonOperator Section */}
      {nonOperators.length > 0 && (
        <>
          <GridItem topSpacing='lg' xs={12}>
            <h2>Non-Operators</h2>
            <Divider className={classes.divider} />
          </GridItem>
          <Grid container className={classes.body} columnSpacing={1.5}>
            {nonOperators.map((driver, index) => (
              <Fragment key={driver.ref}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.root}>
                        <div className={classes.driverDiv}>
                          <p
                            className={classes.headerRight}
                          >{`${driver.firstName} ${driver.lastName}`}</p>
                          <DeltaNonOperatorCard driver={driver} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </>
      )}

      {/* ExcludedOperator Section */}
      {excludedOperators.length > 0 && (
        <>
          <GridItem topSpacing='lg' xs={12}>
            <h2>Excluded Drivers</h2>
            <Divider className={classes.divider} />
          </GridItem>
          <Grid container className={classes.body} columnSpacing={1.5}>
            {excludedOperators.map((driver, index) => (
              <Fragment key={driver.ref}>
                <Grid item xs={12}>
                  <Grid container className={classes.dataGrids}>
                    <Grid item xs={12}>
                      <DeltaDriverCard driver={driver} index={index} />
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </>
      )}

      {/* Affinity Section */}
      <DeltaAffinityCard />

      {/* Drive my way and Miles my way Section */}
      <DeltaProgramsCard drivers={operators} autoProduct={product} />

      {/* Paperless email Section */}
      {paperless.value && <DeltaPaperlessCard product={product} policyType={policyType} />}

      {/* Auto pay type and Auto pay email Section */}
      {autoPay.value && autoPayType.exists && (
        <DeltaAutopayCard product={product} policyType={policyType} />
      )}
    </Grid>
  );
};
