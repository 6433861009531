import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyDeltaField } from '../../utils/usePropertyField';

export const NoOfAmpsQuestion: React.FC<QuestionProps> = (props) => {
  const field = usePropertyDeltaField('root', 'property.noOfAmps', true);
  if (!field) return null;

  const { label = field.question?.title, trackingName = 'no_of_amps_drop_down' } = props;

  return (
    <Select
      {...field.props}
      fullWidth={false}
      id='NoOfAmps'
      groupLabel={label}
      trackingName={trackingName}
    />
  );
};
