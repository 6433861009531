// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesAgentShared } from '../../../../../libs/env/src/index';

export const env: EnvironmentSalesAgentShared = {
  static: {
    isAgent: true,
    baseExp: 'Agent',
    applicationName: 'ecp-ui-sales-agent',
    authKey: 'c978ed8f-c811-48ff-96b7-5e1a4b55b0d1',
  },
  hotjarTrackingId: '3238847',
  datadogService: 'edsp-asp-ui',
  datadogApplicationId: '245b1fcf-cb99-4984-9e42-01f691dc910b',
  datadogClientToken: 'pub40f0ba416e668f5126c9d729df4806de',
  callRecordingServiceUrl: 'ws://localhost:8282/app/rwp',
  genesysCloudApiUrl: 'https://mockapiurl.com',
};
