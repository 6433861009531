import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { Button, Form } from '@ecp/features/sales/shared/components';

import { useStyles } from './PropertyLossesPageForm.styles';

interface Props {
  onNext: () => Promise<void>;
}

export const PropertyLossesPageForm: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const handleSubmit = useCallback(async () => {
    await onNext();
  }, [onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={false}>
        <Grid container>
          <Grid className={classes.buttonsPanel} container item xs={12}>
            <div>
              <Button
                className={classes.next}
                variant='primary'
                onClick={handleSubmit}
                data-testid='propertyLossesContinue'
                trackingName={GoogleAnalyticsLabels.CONTINUE}
                trackingLabel='property_lossess_page_continue'
                analyticsElement='choice.propertyLossesPage.continueButton'
              >
                Continue
              </Button>
            </div>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
