import { useEffect } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { USER_SELECTION } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import {
  getIsBundleForOfferProductsSelected,
  getOfferProductsSelectedByType,
  getSapiAnalyticsSelectedOfferEventDetail,
  getUserSelection,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromProduct, UserSelection } from '@ecp/features/shared/product';

import { AutoDeltaTopBanner } from '../../components/AutoDeltaTopBanner';
import { AutoDeltaPageForm } from '../../forms';

export const AutoDeltaPage: React.FC = () => {
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);
  const dispatch = useDispatch();
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const productName = autoOfferProduct && getProductNameFromProduct(autoOfferProduct);
  const handleNext = useNavigateToNextPage();

  const sapiAnalyticsOfferEventDetail = useSelector(getSapiAnalyticsSelectedOfferEventDetail);

  // TODO: We might need a better way to handle this. A/B test metric tracking function for auto delta page view
  useEffect(() => {
    trackEvent(Events.AUTO_DELTA_PAGE);
  }, []);

  // The dependencies would make sure user selection gets properly updated.
  // Also, this would avoid any mismatch because of edge case back nav scenarios
  // TODO - Revisit this useEffect after SAPI resolves confusion regarding product.userSelection in answers
  const bundleTypeFromInquires = useSelector(getUserSelection);
  const bundleTypeOfSelectedProduct = isBundle ? UserSelection.BUNDLED : UserSelection.UNBUNDLED;
  useEffect(() => {
    // To always keep SAPI updated about the user product selection,
    // Quotes -> AutoDelta
    if (bundleTypeFromInquires === null || bundleTypeFromInquires !== bundleTypeOfSelectedProduct) {
      dispatch(updateAnswers({ answers: { [USER_SELECTION]: bundleTypeOfSelectedProduct } }));
    }
  }, [bundleTypeFromInquires, bundleTypeOfSelectedProduct, dispatch]);

  if (!autoOfferProduct || !productName) return null;

  return (
    <Page
      analyticsElement='choice.autoDeltaPage.Page'
      analyticsEventDetail={sapiAnalyticsOfferEventDetail}
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.AUTO_DELTA} />,
        forceSwipeable: !env.static.isAgent,
      }}
      title={<AutoDeltaTopBanner />}
      variant='normal'
    >
      <AutoDeltaPageForm
        onNext={handleNext}
        autoProduct={autoOfferProduct}
        policyType={productName}
      />
    </Page>
  );
};
