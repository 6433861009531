import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { submitProofDraft, useForm } from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './HomeOtherStructuresPageForm.styles';

export interface HomeOtherStructuresProps {
  onNext: () => Promise<void>;
}

interface SubmitParams {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    setIsSubmitting(true);
    if (validateForm().isValid) {
      await patchFormValues();
      await dispatch(
        submitProofDraft({
          policyTypes: ['home'],
        }),
      );
      await onNext();
    }

    setIsSubmitting(false);
  };

export const HomeOtherStructuresPageForm: React.FC<HomeOtherStructuresProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });

  const handleSubmit = useCallback(async () => {
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
      }),
    );
  }, [dispatch, onNext, patchFormValues, validateForm]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          COMMING SOON: ADD OTHER STRUTURES HERE
          <Grid container item xs={12}>
            <Button
              id='submit'
              variant='primary'
              onClick={handleSubmit}
              data-testid='showMyDiscounts'
              isProcessing={isPatchFormInProgress || isSubmitting}
              classes={{ root: classes.nextButton }}
              className={classes.next}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='home_other_structures_continue'
            >
              Show my discounts
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
