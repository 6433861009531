import { Divider, Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useDeltaField, useField } from '@ecp/features/sales/shared/store';
import type { ProductName } from '@ecp/features/shared/product';
import { type Product } from '@ecp/features/shared/product';
import { DeltaDiscountsEmailQuestion } from '@ecp/sales/lob/auto';

import { DeltaAFTAuthorizationQuestion } from '../DeltaAFTAuthorizationQuestion';
import { DeltaAutopayPaymentOptions } from '../DeltaAutopayPaymentOptions';
import { useStyles } from './DeltaAutopayCard.styles';

interface Props extends QuestionProps {
  product: Product;
  policyType: ProductName;
  isReadOnly?: boolean;
}

export const DeltaAutopayCard: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { product, policyType, isReadOnly } = props;

  const autoPay = useField(`discount.${policyType}.autoPay`);
  const autoPayType = useDeltaField(`coverageOption.${product}`, 'autoPayType');
  const paperlessSelectedField = useField(`discount.${policyType}.paperless`);
  const paperlessSelected =
    paperlessSelectedField.exists && (paperlessSelectedField.value as boolean);

  if (!autoPay.value || !autoPayType.exists) return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <h2>Autopay</h2>
        <Divider className={classes.divider} />
      </GridItem>
      <Grid container className={classes.body} columnSpacing={1.5}>
        <Grid item xs={12}>
          <Grid container className={classes.dataGrids}>
            <Grid item xs={12}>
              <Grid container columnSpacing={1}>
                <GridItem topSpacing='xs' xs={12} md={6}>
                  <DeltaAutopayPaymentOptions product={product} isReadOnly={isReadOnly} />
                </GridItem>
              </Grid>
              <Grid container columnSpacing={1}>
                <GridItem topSpacing='xs' xs={12} md={6}>
                  <DeltaAFTAuthorizationQuestion product={product} isReadOnly={isReadOnly} />
                </GridItem>
              </Grid>
              <GridItem topSpacing='xs' xs={12} md={12}>
                <DeltaDiscountsEmailQuestion
                  product={product}
                  sectionType='autoPay'
                  isPaperlessSelected={paperlessSelected}
                  discountType='paperlessEmail'
                  isReadOnly={isReadOnly}
                />
              </GridItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
